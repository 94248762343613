export const productsCategories = [
  {
    name: "PAPEL HIGIENICO",
    subcategories: [
      {
        name: "HIGIENICO INSTITUCIONAL",
        subcategories: [],
        items: [{ name: "DALIA" }],
      },
      {
        name: "HIGIENICO TRADICIONAL",
        subcategories: [],
        items: [
          { name: "DALIA CLASICO" },
          { name: "LEVEL PRO" },
          { name: "KOVU" },
        ],
      },
      {
        name: "HIGIENICO PRECORTADO",
        subcategories: [],
        items: [{ name: "GREYMOON" }],
      },
    ],
    items: [],
  },
  {
    name: "TOALLAS DE PAPEL",
    subcategories: [
      {
        name: "TOALLA ROLLO INSTITUCIONAL",
        subcategories: [],
        items: [{ name: "FAPSA" }, { name: "FAPSA ECO" }],
      },
      {
        name: "TOALLA INTERDOBLADA",
        subcategories: [],
        items: [{ name: "DALIA" }],
      },
      {
        name: "TOALLA DIFERENCIADA",
        subcategories: [],
        items: [
          { name: "GREYMOON" },
          { name: "PREMIUM (TAD)" },
          { name: "WET CREP (ESTANDAR)" },
        ],
      },
    ],
    items: [],
  },
  {
    name: "SERVILLETAS",
    subcategories: [
      {
        name: "SERVILLETA TRADICIONAL",
        subcategories: [],
        items: [{ name: "DALIA" }, { name: "GREYMOON" }],
      },
      {
        name: "SERVILLETA DE LUJO",
        subcategories: [],
        items: [
          { name: "LEVEL PRO" },
          { name: "FAPSA ECO" },
          { name: "KLEENEX" },
        ],
      },
      {
        name: "SERVILLETA INTERFOLIADA",
        subcategories: [],
        items: [{ name: "DALIA" }],
      },
    ],
    items: [],
  },
  {
    name: "JABONES Y ANTISÉPTICOS",
    subcategories: [
      {
        name: "PURELL",
        subcategories: [
          {
            name: "ES8",
            subcategories: [],
            items: [
              { name: "JABON SUAVE (AUTOMATICO)" },
              { name: "ANTISEPTICO (AUTOMATICO)" },
            ],
          },
          {
            name: "ES10",
            subcategories: [],
            items: [
              { name: "JABON SUAVE (AUTOMATICO)" },
              { name: "ANTISEPTICO (AUTOMATICO)" },
            ],
          },
          {
            name: "FMX-20",
            subcategories: [],
            items: [{ name: "JABON SUAVE (MANUAL)" }],
          },
        ],
        items: [],
      },
      {
        name: "GOJO",
        subcategories: [
          {
            name: "LTX-12",
            subcategories: [],
            items: [{ name: "JABON SUAVE (AUTOMATICO)" }],
          },
          {
            name: "FMX-12",
            subcategories: [],
            items: [{ name: "JABON SUAVE (MANUAL)" }],
          },
          {
            name: "TDX",
            subcategories: [],
            items: [{ name: "DESENGRASANTE DE MANOS (MANUAL)" }],
          },
          {
            name: "NXT",
            subcategories: [],
            items: [{ name: "JABON SUAVE (MANUAL)" }],
          },
        ],
        items: [],
      },
    ],
    items: [],
  },
  {
    name: "QUIMICOS",
    subcategories: [
      {
        name: "DIVERSEY",
        subcategories: [],
        items: [
          { name: "CUIDADO DE COCINAS" },
          { name: "CUIDADO DE EDIFICIOS" },
          { name: "CUIDADO DE PISOS" },
          { name: "HIGIENE Y CUIDADO PERSONAL" },
        ],
      },
      {
        name: "LINEA AURIQUIM",
        subcategories: [],
        items: [
          { name: "BLANQUEADORES" },
          { name: "DETERGENTES" },
          { name: "HIGIENE DE MANOS" },
          { name: "DESENGRASANTES" },
          { name: "LIMPIADORES" },
          { name: "DETERGENTES EN POLVO" },
        ],
      },
    ],
    items: [],
  },
  {
    name: "FIBRAS Y CEPILLOS",
    subcategories: [
      {
        name: "FIBRAS Y ESPONJAS",
        subcategories: [],
        items: [{ name: "3M" }, { name: "SCOTCH-BRITE" }],
      },
      {
        name: "TRAPOS DE LIMPIEZA",
        subcategories: [],
        items: [
          { name: "3M" },
          { name: "MICROFIBRA" },
          { name: "CARPE" },
          { name: "FRANELAS" },
          { name: "JERGAS" },
        ],
      },
      {
        name: "FIBRAS METALICAS",
        subcategories: [],
        items: [{ name: "CHICA" }, { name: "JUMBO" }],
      },
      {
        name: "CEPILLOS DE MANO",
        subcategories: [],
        items: [
          { name: "CEPILLO TIPO PLANCHA (OLIMPIA)" },
          { name: "CEPILLO PRACTICO (OLIMPIA)" },
        ],
      },
      {
        name: "CEPILLOS DE PISO",
        subcategories: [],
        items: [],
      },
    ],
    items: [],
  },
  {
    name: "JARCERIA",
    subcategories: [
      {
        name: "LINEA DE TRAPEADORES",
        subcategories: [],
        items: [
          { name: "MICROFIBRA ESPAÑOLA" },
          { name: "MICROTELA" },
          { name: "PABILO" },
          { name: "HILAZA" },
        ],
      },
      {
        name: "LINEA DE ESCOBAS",
        subcategories: [],
        items: [
          { name: "MIJO" },
          { name: "TIPO ABANICO" },
          { name: "SUPER FINA" },
        ],
      },
    ],
    items: [],
  },
];
