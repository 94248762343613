import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProductService from "services/ProductService";
import {productsCategories} from "../../mock/data/categoriesData";

export const getProducts = createAsyncThunk(
  "user/fetchProducts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductService.getProducts(data);
      const users = response.data;
      return users;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const updateProduct = createAsyncThunk(
  "user/updateProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductService.updateProduct(data);
      const users = response.data;
      return users;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    data: [],
    categories: productsCategories || [],
    status: "idle",
    meta: null,
    product: {
      id: 0,
    },
  },
  reducers: {
    setProduct: (state, action) => {
      state.product = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getProducts.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(updateProduct.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setProduct } = productSlice.actions;

export default productSlice.reducer;
