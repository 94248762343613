import fetch from 'auth/FetchInterceptor'
import { env } from '../configs/EnvironmentConfig'

const ProductService = {}

ProductService.getProducts = function (data) {
	return fetch({
		url: `${env.API_ENDPOINT_URL}/productos/listar`,
		method: 'post',
		data: data
	})
}

ProductService.updateProduct = function (data) {
	return fetch({
		url: `${env.API_ENDPOINT_URL}/productos/editar`,
		method: 'put',
		data: data
	})
}

ProductService.getProductsCatalog = function (data) {
	return fetch({
		url: `${env.API_ENDPOINT_URL}/catalogos/listarProductoCatalogo`,
		method: 'post',
		data: data
	})
}

export default ProductService;