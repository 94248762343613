import fetch from 'auth/FetchInterceptor'
import { env } from '../configs/EnvironmentConfig'
const CatalogService = {}

CatalogService.getCatalogs = function (data) {
	return fetch({
		url: `${env.API_ENDPOINT_URL}/catalogos/listar`,
		method: 'post',
		data: data
	})
}

CatalogService.addCatalog = function (data) {
	return fetch({
		url: `${env.API_ENDPOINT_URL}/catalogos/agregar`,
		method: 'post',
		data: data
	})
}

CatalogService.editCatalog = function (data) {
	return fetch({
		url: `${env.API_ENDPOINT_URL}/catalogos/editar`,
		method: 'put',
		data: data
	})
}

CatalogService.addProductCatalog = function (data) {
	return fetch({
		url: `${env.API_ENDPOINT_URL}/catalogos/editarCatalogoProducto`,
		method: 'put',
		data: data
	})
}

export default CatalogService;