import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from 'services/UserService';

export const getUsers = createAsyncThunk('user/fetchUser',async (data, { rejectWithValue }) => {
	try {
		const response = await UserService.getUsers({})
		const users = response.data;
		return users;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: [],
    status: 'idle',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(getUsers.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default userSlice.reducer;