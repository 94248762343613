import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CatalogService from "services/CatalogService";
import ProductService from "services/ProductService";

export const getProductCatalog = createAsyncThunk(
  "user/fetchProductCatalog",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductService.getProductsCatalog({ id: data });
      const users = response.data;
      return users;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getCatalogs = createAsyncThunk(
  "catalog/fetchProducts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CatalogService.getCatalogs(data);
      const users = response.data;
      return users;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const addCatalog = createAsyncThunk(
  "catalog/fetchCatalog",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CatalogService.addCatalog(data);
      const users = response.data;
      return users;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const editCatalog = createAsyncThunk(
  "catalog/fetcheEditCatalog",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CatalogService.editCatalog(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const addProductCatalog = createAsyncThunk(
  "catalog/fetcheaddProductCatalog",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CatalogService.addProductCatalog(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

const catalogSlice = createSlice({
  name: "catalog",
  initialState: {
    data: [],
    products: [],
    status: "idle",
    meta: null,
    product: {
      id: 0,
    },
  },
  reducers: {
    setCatalog: (state, action) => {
      state.product = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCatalogs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCatalogs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getCatalogs.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(addCatalog.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data.push(action.payload);
      })
      .addCase(getProductCatalog.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload;
      })
      .addCase(getProductCatalog.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(editCatalog.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.data.findIndex(item => item.id == action.payload.id);
        if (index) {
          state.data[index] = action.payload;
        }
      })
      .addCase(editCatalog.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setCatalog } = catalogSlice.actions;

export default catalogSlice.reducer;
