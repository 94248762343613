import fetch from 'auth/FetchInterceptor'
import { env } from '../configs/EnvironmentConfig'

const CatalogService = {}

CatalogService.getClients = function (data) {
	return fetch({
		url: `${env.API_ENDPOINT_URL}/clientes/listar`,
		method: 'post',
		data: data
	})
}

export default CatalogService;