import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ClientService from "services/ClientService";
import CotizationService from "services/CotizationService";

export const getClients = createAsyncThunk(
  "catalog/fetchProducts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ClientService.getClients(data);
      const clients = response.data;
      return clients;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getCotizations = createAsyncThunk(
  "cotization/fetchProducts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CotizationService.getCotizations(data);
      const users = response.data;
      return users;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getCotization = createAsyncThunk(
  "cotization/getCotization",
  async (data, { rejectWithValue }) => {
    try {
      console.log('data =>', data)
      const response = await CotizationService.getCotization(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const addCotization = createAsyncThunk(
  "cotization/fetchCatalogData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CotizationService.addCotization(data);
      const users = response.data;
      return users;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const removeCotization = createAsyncThunk(
  "cotization/fetchRemoveCatalog",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CotizationService.removeCotization(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

const catalogSlice = createSlice({
  name: "cotization",
  initialState: {
    data: [],
    result: {},
    clients: [],
    status: "idle",
  },
  reducers: {
    setCatalog: (state, action) => {
      state.product = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClients.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.clients = action.payload;
      })
      .addCase(getCotizations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCotizations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getCotizations.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(addCotization.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCotization.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data.push(action.payload);
      })
      .addCase(addCotization.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(removeCotization.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getCotization.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.result = action.payload;
      })
      .addCase(removeCotization.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setCatalog } = catalogSlice.actions;

export default catalogSlice.reducer;
