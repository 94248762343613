import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'detailCatalog',
        path: `catalogo/:id`,
        component: React.lazy(() => import('views/app-views/catalog/detalle')),
    },
]

export const protectedRoutes = [
    {
        key: 'users',
        path: `${APP_PREFIX_PATH}/users`,
        component: React.lazy(() => import('views/app-views/users')),
    },
    {
        key: 'products',
        path: `${APP_PREFIX_PATH}/products`,
        component: React.lazy(() => import('views/app-views/products')),
    },
    {
        key: 'productDetail',
        path: `${APP_PREFIX_PATH}/product/detail`,
        component: React.lazy(() => import('views/app-views/products/detalle')),
    },
    {
        key: 'cotizations',
        path: `${APP_PREFIX_PATH}/cotizations`,
        component: React.lazy(() => import('views/app-views/cotizations')),
    },
    {
        key: 'addcotization',
        path: `${APP_PREFIX_PATH}/cotizations/addcotization`,
        component: React.lazy(() => import('views/app-views/cotizations/addCotization')),
    },
    {
        key: 'detailcotization',
        path: `${APP_PREFIX_PATH}/cotizations/detail/:id`,
        component: React.lazy(() => import('views/app-views/cotizations/detail')),
    },
    {
        key: 'catalog',
        path: `${APP_PREFIX_PATH}/catalog`,
        component: React.lazy(() => import('views/app-views/catalog')),
    },
    {
        key: 'addcatalog',
        path: `${APP_PREFIX_PATH}/catalog/addCatalog`,
        component: React.lazy(() => import('views/app-views/catalog/addCatalog')),
    },
    {
        key: 'editCatalog',
        path: `${APP_PREFIX_PATH}/catalog/editCatalog`,
        component: React.lazy(() => import('views/app-views/catalog/editCatalog')),
    },
    {
        key: 'detailCatalog',
        path: `${APP_PREFIX_PATH}/catalog/detail/:id`,
        component: React.lazy(() => import('views/app-views/catalog/detalle')),
    },
    
]