import fetch from 'auth/FetchInterceptor'
import { env } from '../configs/EnvironmentConfig'
const CotizationService = {}

CotizationService.getCotizations = function (data) {
	return fetch({
		url: `${env.API_ENDPOINT_URL}/cotizaciones/listar`,
		method: 'post',
		data: data
	})
}

CotizationService.getCotization = function (data) {
	return fetch({
		url: `${env.API_ENDPOINT_URL}/cotizaciones/${data}`,
		method: 'get'
	})
}

CotizationService.addCotization = function (data) {
	return fetch({
		url: `${env.API_ENDPOINT_URL}/cotizaciones/agregar`,
		method: 'post',
		data: data
	})
}

CotizationService.removeCotization = function (data) {
	return fetch({
		url: `${env.API_ENDPOINT_URL}/cotizaciones/eliminar`,
		method: 'delete',
		data: data
	})
}

export default CotizationService;