import fetch from 'auth/FetchInterceptor'
import { env } from '../configs/EnvironmentConfig'

const UserService = {}

UserService.getUsers = function (data) {
	return fetch({
		url: `${env.API_ENDPOINT_URL}/auth/listar`,
		method: 'post',
		data: data
	})
}

export default UserService;